import { IconButton } from '@patrianna/core-components'
import type { SnackbarVariants } from '@patrianna/shared-patrianna-types/store/SnackbarModule'
import cx from 'classnames'
import Button from 'components/Button'
import ContactUsButton from 'components/ContactUsButton'
import MessageContent from 'containers/GlobalSnackbar/MessageContent'
import { useTranslation } from '@patrianna/core-components'
import { Close } from '@patrianna-icons/material-icons'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'
import { getSnackbarComponentPropsSelector } from 'store/modules/snackbar/selectors'
import classes from './styles.module.scss'

type Props = {
  message: string
  variant: SnackbarVariants
  action?: (...args: unknown[]) => unknown
}

type ComponentProps = {
  title?: string
  buttonText?: string
  isTermsShow?: boolean
}

export default function RichMessage(props: Props) {
  const { message, variant, action } = props
  const componentProps: ComponentProps = useAppSelector(getSnackbarComponentPropsSelector)
  const dispatch = useAppDispatch()
  const t = useTranslation()

  const Title = {
    error: t('common.error'),
    success: t('common.success'),
    warning: t('common.warning'),
    info: t('common.info'),
  }

  const handleClick = () => {
    action?.()
    dispatch(closeSnackbar())
  }

  return (
    <div className={cx(classes.root, 'mt-modal')}>
      <IconButton onClick={handleClick} className={cx(classes.close, 'modal-close')} data-test='close-modal-button'>
        <Close />
      </IconButton>

      <h5 className={cx(classes.messageTitle, 'modal-title mt16 mb0')}>
        {componentProps?.title ? t(componentProps.title) : `${Title[variant]}. ${t('common.review_below')}`}
      </h5>

      <div className='divider' />

      <div className={cx('text-smaller', classes.infoText)}>
        <MessageContent message={message} />
      </div>

      <Button onClick={handleClick} variant='contained' className={cx('button mb0', classes.button, classes[variant])}>
        {componentProps?.buttonText ? t(componentProps.buttonText) : t('common.understood')}
      </Button>

      {componentProps?.isTermsShow && (
        <div className={cx('text-smaller mt16 mb8', classes.contactUs)}>
          {`${t('dialogOrderConfirmationMessage.if_the_issue_persists_please')} `}
          <ContactUsButton />
        </div>
      )}
    </div>
  )
}
