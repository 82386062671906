import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import { useRouter } from 'app/context/navigation'
import { legalRoutes } from 'config/legalRoutes'
import { CONFIRM_EMAIL_STATUS } from 'src/constants/queryParams'
import { useAppSelector } from 'store/hooks'
import { needToVerifyEmailSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

export default function useIsEmailVerified() {
  const router = useRouter()
  const pathname = usePathname()
  const query = useSearchParams()
  const confirmEmailStatus = query.get(CONFIRM_EMAIL_STATUS)
  const emailNotVerified = useAppSelector(needToVerifyEmailSelector)

  useEffect(() => {
    if (emailNotVerified && !legalRoutes.some((route: string) => pathname.includes(route))) {
      const confirmEmailRoute = confirmEmailStatus
        ? `${ROUTES.CONFIRM_EMAIL}?s=${confirmEmailStatus}`
        : ROUTES.CONFIRM_EMAIL
      router.replace(confirmEmailRoute)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [emailNotVerified, pathname])
}
