import type { GetAccountFreeSpinsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountFreeSpinsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'store/types'
import { removeFromHistorySessionDialogs, waitingForSessionDialogsData } from '../sessionDialogs/actions'
import { actions } from './slice'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { getHistorySessionDialogsSelector } from 'store/modules/sessionDialogs/selectors'
import { openDialog } from 'store/modules/dialog/actions'
import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/FreeSpinModule'
import { uniqueBy } from '@patrianna/shared-utils'
import { getFreeSpinsSelector } from 'store/modules/freeSpins/selectors'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'

export const { saveFreeSpins, saveFreeSpinsConfig, setFreeSpinsLoading } = actions

export const getUserFreeSpins =
  (isScEnabled: boolean): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountFreeSpinsRequest = {
      type: 'GetAccountFreeSpinsRequest',
      currency: isScEnabled ? ['SC', 'GC'] : ['GC'],
    }

    dispatch(setFreeSpinsLoading(true))

    gateway
      .emit<GetAccountFreeSpinsResponse>(data)
      .then((respBody) => {
        dispatch(saveFreeSpins({ freeSpins: respBody?.data }))
        dispatch(waitingForSessionDialogsData({ freeSpins: true }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
      .finally(() => {
        dispatch(setFreeSpinsLoading(false))
      })
  }

export const showFSModalFlow =
  (freeSpins: FreeSpinType[]): TypedThunk =>
  (dispatch, getState) => {
    const dialogStack = getDialogStackSelector(getState())
    const historySessionDialogs = getHistorySessionDialogsSelector(getState())
    const isFSSessionWasShown = historySessionDialogs?.includes('FREE_SPINS_DIALOG')

    if (dialogStack.length && isFSSessionWasShown) {
      dispatch(removeFromHistorySessionDialogs({ modalName: 'FREE_SPINS_DIALOG' }))
    }
    // field .fromWS will be added to packages after successful testing
    // @ts-ignore
    freeSpins[0].fromWS = !dialogStack.length
    const uniqueFreeSpins = uniqueBy(
      [...freeSpins, ...getFreeSpinsSelector(getState())],
      (element) => element.freeSpinId
    )

    dispatch(saveFreeSpins({ freeSpins: uniqueFreeSpins }))

    if (!dialogStack.length) {
      dispatch(openDialog({ modalName: 'FREE_SPINS_DIALOG', dialogProps: { freeSpins } }))
    }
  }

export const getFreeSpinsConfig = (): TypedThunk => async (dispatch) => {
  try {
    const apiUrl = `${process.env.GAME_INTEGRATION}/api/free-spins`
    const response = await fetch(apiUrl, { method: 'POST' })

    if (!response.ok) {
      throw new Error('Failed to fetch free spins config')
    }

    const data = await response.json()

    if (data) {
      dispatch(saveFreeSpinsConfig(data))
    }
  } catch (error) {
    console.error('Error fetching free spins config: ', error)
  }
}

export const updateFSBalance =
  (gameProduct: GameProduct): TypedThunk =>
  (dispatch, getState) => {
    const isScEnabled = sweepstakeEnabledSelector(getState())
    const freeSpins = getFreeSpinsSelector(getState())

    if (freeSpins.some((fs) => fs?.products?.[0]?.code === gameProduct?.code)) {
      dispatch(getUserFreeSpins(isScEnabled))
    }
  }
