import { dialogSlice } from 'store/modules/dialog/slice'
import store from 'store/index'
import { pubSubManager } from 'services/pubsub_con'
import { updateUserRedeem, getAccountInfo } from 'store/modules/user/actions'
import type { User } from '@patrianna/shared-patrianna-types/store/UserModule'
import {
  DialogChannel,
  RedeemChannel,
  RedeemEvent,
  RouterChannel,
  SnackbarChannel,
  UserChannel,
  UserEvent,
} from '@patrianna-payments/mf-channels'
import {
  redeemMoneyRequest,
  resolveRightRedeemWay,
  redeemMoney,
  redeemFlowStart,
  cancelRedeemGeneralFlow,
} from 'store/modules/redeem/actions'
import type { Currency } from 'temp/payment/client'
import { initPrizeout } from 'services/prizeout/actions'
import type { GetTemporaryTokenResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { snackbarSlice } from 'store/modules/snackbar/slice'

const dialogChannel = pubSubManager.createActionChannel(DialogChannel.Actions)

dialogChannel.subscribe((event, payload) => {
  const { actions } = dialogSlice
  // @ts-ignore
  const fn = actions[event]
  if (fn) {
    store.dispatch(fn(payload))
  }
})

const snackbarChannel = pubSubManager.createActionChannel(SnackbarChannel.Actions)

snackbarChannel.subscribe((event, payload) => {
  const { actions } = snackbarSlice
  // @ts-ignore
  const fn = actions[event]

  if (fn) {
    store.dispatch(fn(payload))
  }
})

const userChannel = pubSubManager.createActionChannel(UserChannel.Actions)

userChannel.subscribe((event, payload) => {
  const { actions } = dialogSlice

  if (event === UserEvent.UpdateUser) {
    const userUpdatedCb = (user: User) => {
      userChannel.publish(UserEvent.Updated, user)
    }
    // @ts-ignore
    store.dispatch(updateUserRedeem(payload.user, payload?.openRedeem, userUpdatedCb))
  }

  if (event === UserEvent.Refresh) {
    store.dispatch(getAccountInfo())
  }

  // @ts-ignore
  const fn = actions[event]
  if (fn) {
    store.dispatch(fn(payload))
  }
})

const redeemChannel = pubSubManager.createActionChannel(RedeemChannel.Action)
const routerChannel = pubSubManager.createActionChannel(RouterChannel.Actions)

// todo temporary solution for complex redux actions
redeemChannel.subscribe((event, payload) => {
  if (event == 'redeem_flow_start') {
    const push = (path: string) => {
      routerChannel.publish('push', { path })
    }
    store.dispatch(redeemFlowStart(push))
  }

  if (event == RedeemEvent.ResolveRightRedeemWay) {
    store.dispatch(resolveRightRedeemWay(payload as Currency))
  }

  if (event === RedeemEvent.InitPrizeout) {
    store.dispatch(initPrizeout(payload as GetTemporaryTokenResponse))
  }

  if (event === RedeemEvent.RedeemMoneyRequest) {
    // @ts-ignore // todo fix it
    store.dispatch(redeemMoneyRequest(payload))
  }

  if (event === RedeemEvent.RedeemMoney) {
    // @ts-ignore
    store.dispatch(redeemMoney(payload.redeemFields, payload.seonSessionId))
  }

  if (event === RedeemEvent.CancelRedeemGeneralFlow) {
    // @ts-ignore
    store.dispatch(cancelRedeemGeneralFlow(payload))
  }
})
