'use client'

import { GraphyteScript } from '@patrianna-payments/analytics'
import { ClarityScript } from '@patrianna-payments/analytics/clarity/script'
import { graphytePageView } from '@patrianna-payments/analytics/graphyte/events'
import AnalyticsScripts from '@patrianna-payments/analytics/main/core/Scripts'
import Optimizely from '@patrianna-payments/analytics/optimizely/script'
import SaveWebReferrerScript from '@patrianna-payments/analytics/save-web-referrer/script'
import { usePageViewTracker } from '@patrianna/shared-hooks'
import { getCookie } from '@patrianna/shared-utils'
import { getUserOS } from '@patrianna/shared-utils/getUserOS'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import { configureScope } from '@sentry/nextjs'
import { useAuth } from 'app/context/auth'
import Chats from 'containers/Chats'
import GoogleAuthOneTap from 'containers/GoogleAuthOneTap'
import RemoteEntryPaymentGlobal from 'containers/RemoteEntry/global'
import SaveWebReferrer from 'containers/SaveWebReferrer'
import SessionDialogsProvider from 'containers/SessionDialogsProvider'
import TurnOffSpinnerInButtons from 'containers/TurnOffSpinnerInButtons'
import VisibilityProvider from 'containers/VisibilityProvider'
import RandomRewardsProvider from 'containers/RandomRewardsProvider'
import RootDialog from 'dialogs/components/RootDialog'
import { useAnalyticSubscriptions } from 'hooks/mf/useAnalyticSubscription'
import { useAuthCountriesMF } from 'hooks/mf/useAuthCountriesMF'
import { useAuthStatesMF } from 'hooks/mf/useAuthStatesMF'
import { useRouterSubscriptions } from 'hooks/mf/useRouterSubscriptions'
import { useSentrySubscriptions } from 'hooks/mf/useSentryMf'
import useAppInit from 'hooks/useAppInit'
import useIsEmailVerified from 'hooks/useIsEmailVerified'
import useRequireKYCIdentityVerification from 'hooks/useRequireKYCIdentityVerification'
import useSocialRegistration from 'hooks/useSocialRegistration'
import { useFetchProvidersJackpots } from 'features/providers-jackpots'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import { useAppSelector } from 'store/hooks'
import { getGraphyteEnabledSelector } from 'store/modules/user/selectors'

export function RootLayoutClient({ children }: { children: ReactNode }) {
  const { isLoggedIn } = useAuth()

  const graphyteEnabled = useAppSelector(getGraphyteEnabledSelector)

  const isAnalyticsAvailable = process.env.PROFILE === 'prod' || process.env.PROFILE === 'dev'

  useAppInit()
  useRequireKYCIdentityVerification()
  useSocialRegistration()
  useFetchProvidersJackpots()
  // MF start
  useRouterSubscriptions()
  useAnalyticSubscriptions()
  useAuthStatesMF()
  useAuthCountriesMF()
  useSentrySubscriptions()
  // MF end

  usePageViewTracker(() => {
    if (graphyteEnabled) {
      graphytePageView()
    }
  }, [graphyteEnabled])

  useIsEmailVerified()

  useEffect(() => {
    const userId = getCookie(`${process.env.BRAND_NAME.toUpperCase()}AID`, document.cookie)

    if (userId) {
      configureScope((scope) => scope.setUser({ id: userId }))
    }

    document.body.classList.add(getUserOS())
  }, [])

  return (
    <>
      <RemoteEntryPaymentGlobal />
      <GoogleAuthOneTap />
      <SaveWebReferrer />
      <TurnOffSpinnerInButtons />
      {isLoggedIn && <VisibilityProvider />}
      {children}
      <RootDialog />
      <RandomRewardsProvider />

      <SessionDialogsProvider />

      {isAnalyticsAvailable && (
        <>
          <AnalyticsScripts />
          <GraphyteScript profile={process.env.GRAPHYTE_API_KEY} brandKey={process.env.GRAPHYTE_BRAND_KEY} />
          <ClarityScript profile={process.env.CLARITY} />
          <Optimizely url={process.env.OPTIMIZELY_URL} />
        </>
      )}
      {isBrowser() && <Chats />}
      <SaveWebReferrerScript rootUrl={process.env.ROOT_URL} />
      <GlobalSnackbar />
    </>
  )
}
