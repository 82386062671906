import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { LowOnCoinsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { LowOnCoinsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'src/store/types'
import { getGameByCode } from 'src/utils/loadGameInfoGetInitialProps'
import {
  closeAllDialogs,
  closeLatestDialog,
  openDialog,
  removeDialogByName,
  replaceDialog,
} from 'store/modules/dialog/actions'
import { getMaxPriorityOrRandomModalOfferSelector, isDiscountOffersAvailable } from 'store/modules/shop/selectors'
import { getUserRestrictionsSelector, isGuestModeSelector, isLoggedInSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { setActiveCurrency } from '../currencies/actions'
import { actions } from './slice'
import type { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { stringify } from 'querystring'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getSlotStartUrlByGameTags } from 'utils/navigator/games'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { getActiveCurrency, getLowOnCoinsDialogName } from './utils'
import { getActiveGameCodeSelector } from './selectors'
import { publish } from 'hooks/useDomEvent'
import { GAME_RELOAD } from 'config/customEvents'

export const {
  setActiveGameCode,
  clearSlotGameFlow,
  setGoldMinBet,
  setSweepstakeMinBet,
  setLowOnCoinsLastDateOpened,
  setActiveGameTags,
} = actions

export const goToPlayGame =
  (
    game: GameProduct,
    activeCurrency: Currencies,
    routerPush: (path: string, callback?: () => void) => void,
    options?: { [key: string]: string | number | boolean }
  ): TypedThunk =>
  (dispatch, getState) => {
    const state = getState()
    const activeGame = getActiveGameCodeSelector(state)
    const smBreakpoint = 927
    const isMobile = window.innerWidth < smBreakpoint

    const isPlayPage = window.location.pathname.includes('play')
    const rootUrl = getSlotStartUrlByGameTags(game?.tags)

    dispatch(closeAllDialogs())

    const url = `${rootUrl}/${game.route}/${activeCurrency === 'GC' ? '' : 'sweepstake/'}play/${
      isMobile ? 'mobile' : ''
    }`

    if (isPlayPage && game.code === activeGame) {
      publish(GAME_RELOAD)
    } else {
      routerPush(`${url}${options ? `?${stringify(options)}` : ''}`, () => {
        dispatch(setActiveCurrency({ activeCurrencyId: activeCurrency }))
      })
    }
  }

export const startExternalGameFlow =
  (
    game: GameProduct,
    activeCurrency: Currencies,
    routerPush: (path: string, callback?: () => void) => void,
    options: { [key: string]: string | number | boolean; skipOtpMandatoryAndClosed: boolean }
  ): TypedThunk =>
  (dispatch, getState) => {
    const isGuest = isGuestModeSelector(getState())
    const isLoggedIn = isLoggedInSelector(getState())
    const skipOtp = options?.skipOtpMandatoryAndClosed
    const isOTPrequired = isLoggedIn && !skipOtp && activeCurrency === 'SC'
    const userRestrictions = getUserRestrictionsSelector(getState())

    if (!isLoggedIn) {
      dispatch(closeAllDialogs())
      routerPush(ROUTES.LOGIN)
    } else if (isGuest) {
      dispatch(openDialog({ modalName: 'SCPR_AUTH_WAYS_DIALOG' }))
    } else if (isOTPrequired) {
      dispatch(closeAllDialogs())
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))
    } else if (userRestrictions.includes('no_game')) {
      dispatch(closeAllDialogs())
      dispatch(openDialog({ modalName: 'RESTRICT_USER_DIALOG' }))
    } else {
      dispatch(goToPlayGame(game, activeCurrency, routerPush, options))
    }
  }

export const showLowOnCoinsDialog =
  (currencyName: string | string[] | undefined): TypedThunk =>
  (dispatch, getState) => {
    const isOfferAvailable = isDiscountOffersAvailable(getState())
    const activeCurrency = getActiveCurrency(currencyName)
    const modalName = getLowOnCoinsDialogName(activeCurrency)
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
    const offer = getMaxPriorityOrRandomModalOfferSelector(getState())

    dispatch(
      replaceDialog({
        modalName,
        ...(sweepstakeEnabled && {
          dialogProps: {
            triggeredOnLowOnCoins: true,
          },
        }),
      })
    )
    dispatch(setLowOnCoinsLastDateOpened({ lowOnCoinsLastDateOpened: Date.now() }))

    if (isOfferAvailable && offer) {
      dispatch(replaceDialog({ modalName: 'EXTRA_GOLD_DIALOG', dialogProps: { triggeredOnLowOnCoins: true } }))
    }

    return null
  }

export const closeLowOnCoinsDialog =
  (currencyName: string | string[] | undefined): TypedThunk =>
  (dispatch, getState) => {
    const activeCurrency = getActiveCurrency(currencyName)
    const lowOnCoinsDialogName = getLowOnCoinsDialogName(activeCurrency)
    const dialogStack = getDialogStackSelector(getState())

    const isLowOnCoinsDialogOpen = dialogStack.some((dialog) => dialog.modalName === lowOnCoinsDialogName)

    if (isLowOnCoinsDialogOpen) {
      dispatch(removeDialogByName({ modalName: lowOnCoinsDialogName }))
    }

    return null
  }

export const cleanUpGame = (): TypedThunk => (dispatch) => {
  dispatch(clearSlotGameFlow())
}

export const playInfiniteGames =
  (query: NextParsedUrlQuery, routerPush: (path: string, callback?: () => void) => void) =>
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const activeCurrency = query?.currencyName ? 'SC' : 'GC'
    const data: LowOnCoinsRequest = {
      type: 'LowOnCoinsRequest',
    }

    gateway
      .emit<LowOnCoinsResponse>(data)
      .then((body) => {
        if (body.route) {
          getGameByCode(body.route).then((_game) => {
            dispatch(setActiveGameCode({ code: _game.code }))
            dispatch(setGoldMinBet({ goldMinBet: _game.goldMinBet }))
            dispatch(setSweepstakeMinBet({ sweepstakeMinBet: _game.sweepstakeMinBet }))
            dispatch(goToPlayGame(_game, activeCurrency, routerPush))
          })
          closeLatestDialog()
        }
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }
