import { federation } from '@patrianna-payments/payment-remote-entry'
import RemoteEntryPayment from '.'
import React from 'react'
import ReactDOM from 'react-dom'

federation({
  name: 'host',
  shared: {
    react: {
      version: '18.2.0',
      scope: 'default',
      lib: () => React,
      shareConfig: {
        singleton: true,
        requiredVersion: '18.2.0',
      },
    },
    'react-dom': {
      version: '18.2.0',
      scope: 'default',
      lib: () => ReactDOM,
      shareConfig: {
        singleton: true,
        requiredVersion: '18.2.0',
      },
    },
  },
  remotes: [
    {
      name: 'payment',
      entry: `${process.env.PAYMENT_MF_HOST}/mf-manifest.json?t=${Date.now()}`,
    },
  ],
})

function EmptyLoader() {
  return <></>
}

const RemoteEntryPaymentGlobal = () => {
  return (
    <>
      <RemoteEntryPayment module='OtpEvents' CustomLoader={EmptyLoader} />
      <RemoteEntryPayment module='SoftKycSubscription' CustomLoader={EmptyLoader} />
      <RemoteEntryPayment module='RedeemEvents' CustomLoader={EmptyLoader} />
      <RemoteEntryPayment module='AntifraudSeonInitialization' CustomLoader={EmptyLoader} />
    </>
  )
}

export default RemoteEntryPaymentGlobal
