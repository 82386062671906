import {
  providersJackpotsConfig,
  ProvidersJackpotsProvider,
  useFetchProvidersJackpots as useFetchProvidersJackpotsDefault,
  JackpotTickerContainer as JackpotTickerContainerDefault,
  type ProviderContainerProps,
} from '@patrianna-ui-features/providers-jackpots'

import { legalRoutes } from 'config/legalRoutes'
import ROUTES from 'temp/routes.json'

import { useProvidersJackpotsProviderProps } from './hooks'
import classes from './JackpotTickerContainer.module.scss'

providersJackpotsConfig.config = {
  feature: {
    enabled: Boolean(process.env.JACKPOT_ENABLED),
    providers: {
      pragmatic: {
        url: process.env.PRAGMATIC_JACKPOTS,
      },
      habanero: {
        url: process.env.HABANERO_JACKPOTS,
      },
    },
    ignoredRoutesToFetch: [...legalRoutes, ROUTES.LOGIN, ROUTES.REGISTER],
  },
  customClassNames: {
    ticker: {
      gc: classes.gc,
      sc: classes.sc,
    },
  },
}

export function JackpotTickerContainer(props: ProviderContainerProps) {
  const providerProps = useProvidersJackpotsProviderProps()

  return (
    <ProvidersJackpotsProvider {...providerProps}>
      <JackpotTickerContainerDefault {...props} />
    </ProvidersJackpotsProvider>
  )
}

export function useFetchProvidersJackpots() {
  const providerProps = useProvidersJackpotsProviderProps()

  useFetchProvidersJackpotsDefault(providerProps)
}
