'use client'
import type { PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { NavigationProvider } from 'app/context/navigation'
import store from 'src/store'
import { TranslationsProvider } from '@patrianna/core-components'
import { AuthProvider } from 'app/context/auth'
import type { AuthCookies } from '@patrianna/shared-utils/getAuthCookies'
import { CountryConfigProvider } from 'app/context/country'
import { DetectMobileProvider } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { UnSupportedBrowser } from '@patrianna/core-components/src/components/UnSupportedBrowser'
import { translationsCache } from 'temp/translations/translationsCache'

type Props = PropsWithChildren & {
  authCookies: AuthCookies
  isMobile: boolean
  countryCode: string
  locationCountryCode: string | null
  prismicLang: string
}

export function Providers({ children, authCookies, isMobile, countryCode, locationCountryCode, prismicLang }: Props) {
  return (
    <UnSupportedBrowser>
      <DetectMobileProvider isMobile={isMobile}>
        <Provider store={store}>
          <CountryConfigProvider countryCode={countryCode} locationCountryCode={locationCountryCode}>
            <TranslationsProvider prismicLang={prismicLang} translationsCache={translationsCache}>
              <AuthProvider authCookies={authCookies}>
                <NavigationProvider>{children}</NavigationProvider>
              </AuthProvider>
            </TranslationsProvider>
          </CountryConfigProvider>
        </Provider>
      </DetectMobileProvider>
    </UnSupportedBrowser>
  )
}
