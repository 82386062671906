import { useAppSelector } from 'src/store/hooks'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'
import { getHistorySessionDialogsSelector, receivedAllRequestsSelector } from 'store/modules/sessionDialogs/selectors'
import OpenSessionDialogs from './OpenSessionDialogs'
import { useAuth } from 'app/context/auth'
import { needToVerifyEmailSelector } from 'store/modules/user/selectors'

export default function SessionDialogsProvider() {
  const dialogStack = useAppSelector(getDialogStackSelector)
  const historySessionDialogs = useAppSelector(getHistorySessionDialogsSelector)
  // waiting for all requests to open session dialogs
  // daily bonus, offers, legal updates
  const notReceivedAllRequests = useAppSelector(receivedAllRequestsSelector)
  const emailNotVerified = useAppSelector(needToVerifyEmailSelector)
  const { isLoggedIn } = useAuth()

  // Check if current open dialog is session dialog
  const visibleSessionDialog = dialogStack?.find(({ modalName }) => historySessionDialogs.includes(modalName))
  // If current open dialog is session dialog or any dialog isn't open
  const isChangedDialog = !dialogStack.length || visibleSessionDialog

  if (!isLoggedIn || emailNotVerified || notReceivedAllRequests || !isChangedDialog) {
    return null
  }

  return (
    <OpenSessionDialogs
      visibleSessionDialog={visibleSessionDialog?.modalName}
      historySessionDialogs={historySessionDialogs}
    />
  )
}
