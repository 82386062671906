import type { User } from '@patrianna/shared-patrianna-types/store/UserModule'
import type { TypedThunk } from 'store/types'
import ROUTES from 'temp/routes.json'
import { actions } from './slice'

export const { initCurrencies, setActiveCurrency, updateCurrenciesBalance, clearCurrency } = actions

const PLAY_PAGE_REGEX = new RegExp(
  `(${[ROUTES.GAMES_LIVE, ROUTES.GAMES_SLINGO, ROUTES.GAMES_SLOTS].join('|')})/(.*)/play`
)

export const defaultCurrencyHandler =
  (accountMode: User['mode'], isScEnabled: boolean, routerPath: string): TypedThunk =>
  (dispatch) => {
    // do not switch to SC on GC slot games page:
    // /slots/sun-of-egypt-2/play/ - use default GC mode even if SC allowed
    // /slots/indianas-quest/play/mobile/ - use default GC mode even if SC allowed
    if (PLAY_PAGE_REGEX.test(routerPath) && !routerPath.includes('sweepstake')) {
      return null
    }

    // switch to SC by default
    if (accountMode === 'sweepstake_preview' || isScEnabled) {
      dispatch(setActiveCurrency({ activeCurrencyId: 'SC' }))
    }

    return null
  }
