import { useStoreData } from 'services/pubsub_con'

type ReturnType = {
  mandatoryAndClosed?: boolean
  isReady?: boolean
  skip?: boolean
}

export const useOtpData = (): ReturnType => {
  return useStoreData<ReturnType>(
    'otp_data',
    {
      mandatoryAndClosed: true,
      skip: true,
    },
    () => ({})
  )
}
